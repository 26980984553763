import React from 'react';

import {
  convertToUrl,
  getLoadingScreenUrl,
  ProviderWindowService,
} from '../helpers';
import {
  CashierCommunicationTasks,
  ContainerTypes,
  executeTask,
} from '../models';
import { ProcessingOutput, ResultContainerTypes } from '../slices';
import { SubmitInitiator, useCashierArgs } from './useCashierArgs';

interface ProcessingResultFacadeProps {
  isHandlingEnabled: boolean;
  processingOutput: ProcessingOutput;
  formRef: HTMLFormElement;
}

export const useProcessingResult = ({
  isHandlingEnabled,
  processingOutput,
  formRef,
}: ProcessingResultFacadeProps): any => {
  const {
    cashierArgs: { webView, windowExist, platform, direction, submitInitiator },
  } = useCashierArgs();

  const { container, url, method, html, output, parameters } =
    processingOutput || {};

  const content = html || output;

  const handleUserClick = ({ platform, direction }) => {
    ProviderWindowService.initialize(
      ContainerTypes.Window,
      getLoadingScreenUrl({ platform, direction }),
    );
  };

  const fetchUrlContent = React.useCallback(() => {
    fetch(url, {
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(parameters),
      method: method,
    })
      .then((response: Response) => response.text())
      .then((value: string) => {
        executeTask(CashierCommunicationTasks.onPaymentProcessed, {
          status: 200,
          data: {
            html: value,
          },
        });
        executeTask(CashierCommunicationTasks.onPaymentAttributesChanged, []);
      });
  }, [method, url]);

  const clickToNewTab = (url: string): void => {
    ProviderWindowService.redirect(url, windowExist);
  };

  const submitRedirectDataForm = React.useCallback(
    (params?: { userClick: boolean }): void => {
      if (webView && !windowExist) {
        executeTask(CashierCommunicationTasks.submitForm, {
          url,
          method,
          parameters,
        });
      } else {
        if (!windowExist) {
          ProviderWindowService.initialize(
            ContainerTypes.Window,
            url || 'about:blank',
          );
        }

        const { userClick } = params || {};

        if (submitInitiator === SubmitInitiator.self || userClick) {
          formRef.submit();
        } else {
          fetchUrlContent();
        }
      }
    },
    [
      fetchUrlContent,
      formRef,
      method,
      parameters,
      submitInitiator,
      url,
      webView,
      windowExist,
    ],
  );

  const loadContentInNewWindow = (content: string): void => {
    if (windowExist) {
      ProviderWindowService.writeContent(ContainerTypes.Window, content);
    }

    if (!windowExist) {
      ProviderWindowService.initialize(
        ContainerTypes.Window,
        url || 'about:blank',
      );
      ProviderWindowService.writeContent(ContainerTypes.Window, content);
    }
  };

  const handleIframe = React.useCallback(() => {
    if (method === 'POST' && url && formRef) {
      submitRedirectDataForm();
    }
  }, [submitRedirectDataForm, formRef, method, url]);

  const handleWindow = React.useCallback(
    (params?: { userClick: boolean }) => {
      const { userClick } = params || {};
      if (userClick && windowExist) {
        handleUserClick({ platform, direction });
      }

      if (method === 'GET' && url) {
        clickToNewTab(url);
        if (!userClick) {
          executeTask(CashierCommunicationTasks.onPaymentProcessed, {
            status: 200,
            data: {
              redirectUrl: url,
            },
          });
          executeTask(CashierCommunicationTasks.onPaymentAttributesChanged, []);
        }

        return;
      }

      if (content) {
        loadContentInNewWindow(content);

        if (!userClick) {
          executeTask(CashierCommunicationTasks.onPaymentProcessed, {
            status: 200,
            data: {
              html: content,
            },
          });
          executeTask(CashierCommunicationTasks.onPaymentAttributesChanged, []);
        }
        return;
      }

      if (method === 'POST' && formRef) {
        submitRedirectDataForm({ userClick });
        return;
      }
    },
    [formRef, content, method, url, windowExist, platform, direction],
  );

  const handleRedirect = React.useCallback(
    (type: ResultContainerTypes) => {
      if (windowExist) {
        if (
          type === ResultContainerTypes.IFRAME &&
          !!content &&
          method === 'POST'
        ) {
          return loadContentInNewWindow(content);
        }
      }

      if (method === 'GET') {
        const redirectUrl = convertToUrl(url, parameters) || url;

        clickToNewTab(redirectUrl);
        executeTask(CashierCommunicationTasks.onPaymentProcessed, {
          status: 200,
          data: {
            redirectUrl,
          },
        });
        executeTask(CashierCommunicationTasks.onPaymentAttributesChanged, []);
      } else if (formRef && method !== null) {
        submitRedirectDataForm();
      }
    },
    [method, formRef, url, processingOutput, windowExist, content],
  );

  React.useEffect(() => {
    if (isHandlingEnabled) {
      switch (container) {
        case ResultContainerTypes.DIRECT:
          handleRedirect(ResultContainerTypes.DIRECT);
          break;
        case ResultContainerTypes.IFRAME:
          if (windowExist) {
            handleRedirect(ResultContainerTypes.IFRAME);
          }
          if (!windowExist) {
            handleIframe();
          }
          break;
        case ResultContainerTypes.WINDOW:
          handleWindow();
          break;
        case ResultContainerTypes.REDIRECT:
          handleRedirect(ResultContainerTypes.REDIRECT);
          break;
        case ResultContainerTypes.DISPLAY:
          handleRedirect(ResultContainerTypes.DISPLAY);
          break;
      }
    }
  }, [
    isHandlingEnabled,
    container,
    handleIframe,
    handleWindow,
    handleRedirect,
    windowExist,
  ]);

  return { handleWindow };
};
