import { createSelector } from '@reduxjs/toolkit';

import { FinteqHubService } from '../helpers';
import {
  selectAccountCreateInProgress,
  selectActiveAccount,
  selectPaymentMethod,
} from '../slices';

export const selectActiveInputs = createSelector(
  selectPaymentMethod,
  selectAccountCreateInProgress,
  selectActiveAccount,
  (method, isInProgress, account) => {
    const { inputs, account_form_inputs, show_account_form, preprocessor } =
      method || {};
    const showMethodInputs = isInProgress || !account || !show_account_form;

    if (!showMethodInputs) {
      return account_form_inputs;
    }

    return FinteqHubService.enrichInputsWithCardItems(preprocessor, inputs);
  },
);
