export enum CashierCommunicationTasks {
  scrollTop = 'scroll_top',
  processingResult = 'deposit-withdraw_result',
  cashierHeightChange = 'cashier_height_change',
  transactionFailed = 'transactionFailed',
  transactionCanceled = 'transactionCanceled',
  getBackToPaymentMethods = 'getBackToPaymentMethods',
  customerBalance = 'customerBalance',
  submitForm = 'submitForm',
  paymentInitiated = 'payment_initiated',
  depositError = 'deposit_error',

  onPaymentFormReady = 'onPaymentFormReady',
  onPaymentAttributesChanged = 'onPaymentAttributesChanged',
  onPaymentDataSubmitted = 'onPaymentDataSubmitted',
  onPaymentDataSubmitDeclined = 'onPaymentDataSubmitDeclined',
  onPaymentDataPreProcessed = 'onPaymentDataPreProcessed',
  onPaymentDataConfirmationInitiated = 'onPaymentDataConfirmationInitiated',
  onPaymentConfirmed = 'onPaymentConfirmed',
  onPaymentProcessed = 'onPaymentProcessed',
  onPaymentProcessingStarted = 'onPaymentProcessingStarted',
  onBonusSelected = 'onBonusSelected',
  newPaymentInitiated = 'newPaymentInitiated',
  paymentRetried = 'paymentRetried',
}

export const executeTask = (taskType: string, payload?: unknown): void => {
  window.parent.postMessage({ task: taskType, payload: payload }, '*');

  if ('webkit' in window) {
    (window as any).webkit.messageHandlers.mobileApp?.postMessage({
      task: taskType,
      payload: payload,
    });
  }
};

export const cashierDimensionsChangeHandler = (force = true): void => {
  const root = document.getElementById('root');

  if (force || root.scrollHeight > window.innerHeight) {
    executeTask(
      CashierCommunicationTasks.cashierHeightChange,
      root.scrollHeight,
    );
  }
};
