import { getDeviceData } from '@finteqhub/sdk-js/dist/src/utils';
import { v4 as uuidv4 } from 'uuid';

import { CashierFieldPreprocessor, FieldType } from '../models';
import { PaymentMethodInput, Preprocessor } from '../slices';
import { handleDefaultField } from './paymentFormFields';

interface IFinteqHubService {
  enrichInputsWithCardItems: (
    preprocessor: Preprocessor,
    inputs: PaymentMethodInput[],
  ) => PaymentMethodInput[];
  enrichInputsWithSessionItems: (
    preprocessor: Preprocessor,
    input: PaymentMethodInput,
  ) => PaymentMethodInput;
}

const deviceData = {
  ...getDeviceData().device.browser,
  fingerprint: uuidv4().replaceAll('-', ''),
  type: getDeviceData().device.type,
  javaEnabled: String(getDeviceData().device.browser.javaEnabled),
  javaScriptEnabled: String(getDeviceData().device.browser.javaScriptEnabled),
};

const shouldProcessSession = (preprocessor?: Preprocessor) => {
  return [
    CashierFieldPreprocessor.FINTEQ_HUB_CARD_WITHDRAWAL,
    CashierFieldPreprocessor.FINTEQ_HUB_CARD_DEPOSIT,
  ].includes(preprocessor?.type as CashierFieldPreprocessor);
};

const finteqInputs: PaymentMethodInput[] = [
  {
    name: 'cardHolder',
    label: 'Cardholder Name',
    required: true,
    type: FieldType.Input,
    placeholder: 'John Doe',
    value: null,
    params: {
      options: [],
      preprocessed: false,
      shouldBeStripped: true,
    },
  },
  {
    name: 'creditcardNumber',
    label: 'Credit Card Number',
    required: true,
    type: FieldType.CreditCardNumber,
    placeholder: '5xxx-xxxx-xxxx-xxxx',
    value: null,
    params: {
      options: [],
      preprocessed: true,
      shouldBeStripped: true,
    },
  },
  {
    name: 'cvv',
    label: 'CVV',
    required: true,
    type: FieldType.Cvv,
    placeholder: '111',
    value: null,
    params: {
      options: [],
      preprocessed: true,
      shouldBeStripped: true,
    },
  },
  {
    name: 'expirationDate',
    label: 'Expiration Date',
    required: true,
    type: FieldType.DateTime,
    placeholder: '12/2222',
    value: null,
    params: {
      options: [],
      preprocessed: true,
      shouldBeStripped: true,
    },
  },
];

export const FinteqHubService: IFinteqHubService = {
  enrichInputsWithCardItems(
    preprocessor: Preprocessor,
    inputs: PaymentMethodInput[],
  ) {
    if (
      preprocessor?.type !== CashierFieldPreprocessor.FINTEQ_HUB_CARD_DEPOSIT
    ) {
      return inputs;
    }

    return [...inputs, ...finteqInputs];
  },
  enrichInputsWithSessionItems(
    preprocessor: Preprocessor,
    input: PaymentMethodInput,
  ) {
    if (input.params?.preprocessed && shouldProcessSession(preprocessor)) {
      return {
        ...input,
        value: deviceData[input.name] ?? null,
        isValid: Boolean(deviceData[input.name]),
      };
    }

    return handleDefaultField(input);
  },
};
