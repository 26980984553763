import { ContainerTypes } from '../models';

const openedWindowMap: Record<string, WindowProxy> = {};

interface IProviderWindowService {
  initialize: (name: string, path?: string) => void;
  redirect: (url: string, isNewWindow?: boolean) => void;
  writeContent(name: string, content: string): void;
  close: (name?: string) => void;
  get: (name: string) => WindowProxy | null;
}

const isWindowOpened = (name: string): boolean => {
  return (
    !!openedWindowMap[name] &&
    !!openedWindowMap[name].frames &&
    !openedWindowMap[name].closed
  );
};

export const ProviderWindowService: IProviderWindowService = {
  initialize(name: string, path?: string) {
    const openedWindow = this.get(name);
    if (openedWindow) {
      openedWindow.focus();

      return;
    }

    const newWindow = window.open(path || '/loading', '_blank');
    if (newWindow) {
      openedWindowMap[name] = newWindow;
      try {
        newWindow.name = name;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.info('cross origin request');
      }
    }
  },

  redirect(url: string, isNewWindow = true) {
    if (!isNewWindow) {
      const a = document.createElement('a');
      a.href = url;
      a.click();

      return;
    }

    const openedWindow = this.get(ContainerTypes.Window);

    if (openedWindow) {
      openedWindow.location = url;

      return;
    }

    this.initialize(ContainerTypes.Window, url);
  },

  close(name?: string): void {
    if (!name) {
      window.close();
      return;
    }

    const openedWindow = this.get(name);

    if (openedWindow) {
      try {
        openedWindow.close();
        delete openedWindowMap[name];
      } catch (e) {
        // eslint-disable-next-line no-console
        console.info('probably cross origin frame try to close');
      }
    }
  },

  writeContent(name: string, content: string) {
    const newWindow = this.get(name);

    if (newWindow) {
      newWindow.document.write(content);
    }
  },

  get(name: string): WindowProxy | null {
    if (!isWindowOpened(name)) {
      return null;
    }

    return openedWindowMap[name];
  },
};
