import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { AMOUNT_FIELD_NAME, CREDITCARD_CATEGORY_NAME } from '../constants';
import { FinteqHubService, getCookie, QaToolKitOptions } from '../helpers';
import {
  handleAmountField,
  handleDefaultField,
  handleSelectField,
} from '../helpers';
import { TransactionType } from '../interfaces';
import { CashierCommunicationTasks, FieldType } from '../models';
import { selectActiveInputs, selectFieldsByType } from '../selectors';
import {
  FieldsByTypeInterface,
  getCertainPaymentMethodStart,
  hideTransactionButtonSelector,
  initiateProcessing,
  PaymentMethod,
  PaymentMethodInput,
  ProcessPaymentResponse,
  resetPaymentMethod,
  selectAccountCreateInProgress,
  selectAmount,
  selectCurrencySymbol,
  selectPaymentFormActivated,
  selectPaymentInputs,
  selectPaymentMethod,
  selectPaymentMethodSelectedManually,
  selectPaymentMethodsLoading,
  selectProcessingLoading,
  selectProcessingResponse,
  selectRegistrationRequired,
  setAmount,
  setPaymentInputs,
  setPgwError,
  setTermsAgreementCheckbox,
} from '../slices';
import { useAmountValidationErrors } from './useAmountValidationErrors';
import { useCashierArgs } from './useCashierArgs';

interface UsePaymentFormProps {
  total: number;
  areInputsValid: boolean;
  customInputChange: (
    name: string,
    value: string,
    isValid: boolean,
    isFocused: boolean,
  ) => void;
  updateAmount: (
    name: string,
    value: string,
    isValid?: boolean,
    isFocused?: boolean,
  ) => void;
  favoriteAmountClickHandler: (amount: string | number) => void;
  favoriteAmountKeyboardHandler: (
    event: React.SyntheticEvent,
    amount: number,
  ) => void;
  agreeCheckboxClick: () => void;
  agree: boolean;
  paymentInputs: Record<string, PaymentMethodInput>;
  isCheckboxVisible: boolean;
  isButtonDisabled: boolean;
  areFavoriteAmountsVisible: boolean;
  isAccountCreateInProgress: boolean;
  isVisible: boolean;
  resetValues: boolean;
  isActivated: boolean;
  isLoaderActive: boolean;
  arePaymentMethodsLoading: boolean;
  userAmount: string;
  paymentMethod: PaymentMethod;
  activeType: string;
  activeInputs: PaymentMethodInput[];
  amountValidationErrors: string[];
  processingResponse: ProcessPaymentResponse;
  resetPaymentMethod: () => void;
  initiateProcessing: () => void;
  currency: string;
  hideTransactionButton: boolean;
  currentFields: FieldsByTypeInterface[];
  isRegistrationRequired: boolean;
  fetchCertainPaymentMethod: (
    type: TransactionType,
    methodId: string,
    bonusCode: string,
  ) => void;
}

export const usePaymentForm = (): UsePaymentFormProps => {
  const isProcessingLoading = useSelector(selectProcessingLoading);
  const userAmount = useSelector(selectAmount);
  const processingResponse = useSelector(selectProcessingResponse);
  const paymentMethod = useSelector(selectPaymentMethod);
  const activeInputs = useSelector(selectActiveInputs);
  const paymentInputs = useSelector(selectPaymentInputs);
  const isAccountCreateInProgress = useSelector(selectAccountCreateInProgress);
  const isPaymentFormActivated = useSelector(selectPaymentFormActivated);
  const isFinalScreenActivated = useSelector(selectPaymentFormActivated);
  const isPaymentMethodSelectedManually = useSelector(
    selectPaymentMethodSelectedManually,
  );
  const arePaymentMethodsLoading = useSelector(selectPaymentMethodsLoading);
  const currency = useSelector(selectCurrencySymbol);
  const hideTransactionButton = useSelector(hideTransactionButtonSelector);
  const currentFields = useSelector(selectFieldsByType);
  const isRegistrationRequired = useSelector(selectRegistrationRequired);

  const amountValidationErrors = useAmountValidationErrors();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isValidationDisabled =
    getCookie(QaToolKitOptions.disableValidation) === 'true';

  const inputErrorMsgMap = {
    [FieldType.Cvv]: t('cvvValidationFailed.message'),
    [FieldType.DateTime]: t('dateFormatValidationFailed.message'),
    [FieldType.Email]: t('emailValidationFailed.message'),
    [FieldType.CreditCardVisa]: t('cardNumberValidationFailed.message'),
    [FieldType.CreditCardMastercard]: t('cardNumberValidationFailed.message'),
    [FieldType.CreditCardNumber]: t('cardNumberValidationFailed.message'),
    [FieldType.IdentificationNumber]: t('rucIdValidationFailed.message'),
  };

  const noCheckboxMethodIds = React.useMemo(() => {
    return [
      'payment-iq-cryptocurrency-quickbit',
      'payment-iq-webredirect-flykk',
      'SwitchPaymentCard',
    ];
  }, []);

  const [total, setTotal] = React.useState<number>(0);
  const [agree, setAgree] = React.useState<boolean>(false);
  const [areInputsValid, setInputsValid] = React.useState<boolean>(false);
  const [stateValues, setStateValues] = React.useState({});
  const {
    cashierArgs: { amount: platformAmount, activeType },
  } = useCashierArgs();

  const amount = userAmount || platformAmount;

  const { id, hideAmount, categories, ruleParams } = paymentMethod;
  const { amounts } = ruleParams || {};

  React.useEffect(() => {
    setStateValues({});
    setAgree(false);
  }, [paymentMethod]);

  React.useEffect(() => {
    if (activeInputs) {
      const valuesSet = activeInputs.reduce((acc, properties) => {
        if (properties.type === FieldType.Select) {
          acc[properties.name] = handleSelectField(properties);
        } else if (properties.name === AMOUNT_FIELD_NAME) {
          acc[properties.name] = handleAmountField(paymentInputs, amount);
        } else {
          acc[properties.name] = FinteqHubService.enrichInputsWithSessionItems(
            paymentMethod.preprocessor,
            properties,
          );
        }

        return acc;
      }, {});
      setStateValues(valuesSet);
    }
  }, [activeInputs]);

  React.useEffect(() => {
    dispatch(setPaymentInputs(stateValues));
  }, [stateValues, dispatch]);

  React.useEffect(() => {
    const x = Object.values(stateValues).find(
      ({ isValid, required, name, type, value }) => {
        if (
          !required ||
          name === AMOUNT_FIELD_NAME ||
          name === 'accountId' ||
          (type === FieldType.Hidden && Boolean(String(value)))
        )
          return false;

        return !isValid;
      },
    );

    setInputsValid(!x);
  }, [stateValues]);

  React.useEffect(() => {
    if (!stateValues[AMOUNT_FIELD_NAME]) return;

    const floatAmount = parseFloat(stateValues[AMOUNT_FIELD_NAME].value);
    floatAmount > 0 ? setTotal(floatAmount) : setTotal(0);
  }, [stateValues]);

  const customInputChange = (name, value, isValid, isFocused = true) => {
    const paymentMethodInput = activeInputs.find(
      ({ name: inputName }) => inputName === name,
    );

    if (paymentMethodInput) {
      if (processingResponse?.errors && processingResponse?.errors[name]) {
        // eslint-disable-next-line no-unsafe-optional-chaining
        const { [name]: key, ...rest } = processingResponse?.errors;
        dispatch(setPgwError(rest || {}));
      }

      // add function to modify mask depending on value
      setStateValues((old) => ({
        ...old,
        [name]: {
          ...paymentMethodInput,
          value: value,
          isValid: isValidationDisabled ? true : isValid,
          errorMsg: value && inputErrorMsgMap[paymentMethodInput.type],
          isFocused,
        },
      }));
    }
  };

  const updateAmount = (
    name: string,
    newAmount: string,
    isValid?: boolean,
    isFocused?: boolean,
  ): void => {
    const paymentMethodInput = activeInputs.find(
      ({ name: inputName }) => inputName === name,
    );

    setStateValues((old) => ({
      ...old,
      [name]: {
        ...paymentMethodInput,
        value: newAmount.toString(),
        isValid: isValidationDisabled ? true : isValid,
        errorMsg: null,
        isFocused,
      },
    }));

    dispatch(setAmount(newAmount.toString()));
  };

  const favoriteAmountClickHandler = React.useCallback(
    (amount: number) => {
      if (!isProcessingLoading) {
        dispatch(setAmount(amount.toString()));
      }
    },
    [isProcessingLoading],
  );

  const favoriteAmountKeyboardHandler = (event, amount: number) => {
    if (event.keyCode === 13) {
      dispatch(setAmount(amount.toString()));
    }
  };

  const toggleAgree = () => {
    setAgree(!agree);
    dispatch(
      setTermsAgreementCheckbox({
        isCheckboxVisible: true,
        isChecked: !agree,
      }),
    );
  };

  const isCheckboxVisible = React.useMemo((): boolean => {
    // Show checkbox only if alternativeMessage exists OR agreement message and link exists
    const requiredTranslationsExists =
      !!(t('agreement.message', '') && t('agreement.link', '')) ||
      !!t('agreement.alternativeMessage', '');

    return (
      !hideAmount &&
      categories.some(
        (category) => category.name === CREDITCARD_CATEGORY_NAME,
      ) &&
      !noCheckboxMethodIds.includes(id) &&
      activeType === TransactionType.Deposit &&
      requiredTranslationsExists
    );
  }, [id, hideAmount, activeType, categories]);

  React.useEffect(() => {
    if (isCheckboxVisible) {
      dispatch(
        setTermsAgreementCheckbox({
          isCheckboxVisible: true,
          isChecked: false,
        }),
      );
    }
  }, [isCheckboxVisible, dispatch]);

  const isButtonDisabled = React.useMemo(() => {
    const { id, hideAmount } = paymentMethod;
    const { amount: amountField = null as PaymentMethodInput } = paymentInputs;

    if (getCookie(QaToolKitOptions.disableValidation) === 'true') return false;
    if (hideAmount && areInputsValid) return false;
    if (null !== amountField && !amount) return true;

    return (
      !id ||
      isProcessingLoading ||
      !areInputsValid ||
      !!amountValidationErrors.length ||
      (isCheckboxVisible && !agree)
    );
  }, [
    amount,
    paymentMethod,
    paymentInputs,
    isProcessingLoading,
    areInputsValid,
    amountValidationErrors,
    noCheckboxMethodIds,
    agree,
    activeType,
    categories,
  ]);

  const areFavoriteAmountsVisible = React.useMemo((): boolean => {
    return (
      activeType === TransactionType.Deposit &&
      amounts.length > 0 &&
      !hideAmount &&
      !platformAmount
    );
  }, [activeType, amounts, hideAmount, platformAmount]);

  const initiateProcessingHandler = React.useCallback(() => {
    dispatch(initiateProcessing());
  }, [dispatch]);

  const callProcessingOnPlatformTrigger = React.useCallback(
    (event: MessageEvent) => {
      if (
        event.data.task === CashierCommunicationTasks.onPaymentDataPreProcessed
      ) {
        initiateProcessingHandler();
      }
    },
    [initiateProcessingHandler],
  );

  React.useEffect(() => {
    window.addEventListener('message', callProcessingOnPlatformTrigger);

    return () =>
      window.removeEventListener('message', callProcessingOnPlatformTrigger);
  }, [initiateProcessingHandler, callProcessingOnPlatformTrigger]);

  const resetPaymentMethodHandler = React.useCallback(() => {
    dispatch(resetPaymentMethod());
  }, [dispatch]);

  const fetchCertainPaymentMethod = React.useCallback(
    (
      transactionType: TransactionType,
      paymentMethodId: string,
      bonusCode?: string,
    ) => {
      dispatch(
        getCertainPaymentMethodStart({
          transactionType,
          paymentMethodId,
          bonusCode,
        }),
      );
    },
    [dispatch],
  );

  return {
    total,
    areInputsValid,
    customInputChange,
    updateAmount,
    favoriteAmountClickHandler,
    favoriteAmountKeyboardHandler,
    agreeCheckboxClick: toggleAgree,
    agree,
    paymentInputs,
    isCheckboxVisible,
    isButtonDisabled,
    areFavoriteAmountsVisible,
    isAccountCreateInProgress,
    isVisible: isPaymentFormActivated,
    resetValues: isFinalScreenActivated,
    isActivated: isPaymentMethodSelectedManually,
    isLoaderActive: isProcessingLoading,
    arePaymentMethodsLoading,
    userAmount,
    paymentMethod,
    activeType,
    activeInputs,
    amountValidationErrors,
    processingResponse,
    resetPaymentMethod: resetPaymentMethodHandler,
    initiateProcessing: initiateProcessingHandler,
    currency,
    hideTransactionButton,
    currentFields,
    isRegistrationRequired,
    fetchCertainPaymentMethod,
  };
};
