import {
  ContainerChannelType,
  NextStepChannelTypes,
} from '@cashier/cashier-common';

export const isRedirectStepValid = ({
  types,
  containers,
}: {
  types: NextStepChannelTypes[];
  containers: ContainerChannelType[];
}): boolean => {
  if (!types || !containers) {
    return true;
  }

  if (types.includes('display')) {
    return false;
  }

  return !(types.includes('html') && containers.includes('iframe'));
};
