import { createSelector } from '@reduxjs/toolkit';

import { PaymentMethodsState } from './interfaces';
import { PAYMENT_METHODS_FEATURE_KEY } from './';

export const getPaymentMethodsState = (rootState: any): PaymentMethodsState =>
  rootState[PAYMENT_METHODS_FEATURE_KEY];

export const selectPaymentMethodsLoading = createSelector(
  getPaymentMethodsState,
  (s) => s.arePaymentMethodsLoading,
);

export const selectPaymentMethodSelectedManually = createSelector(
  getPaymentMethodsState,
  (s) => s.isPaymentMethodSelectedManually,
);

export const selectLoadCertainPaymentMethod = createSelector(
  getPaymentMethodsState,
  (s) => s.loadCertainPaymentMethod,
);

export const selectPaymentMethod = createSelector(
  getPaymentMethodsState,
  (s) => s.paymentMethod,
);

export const selectPaymentMethodeError = createSelector(
  getPaymentMethodsState,
  (s) => s.error,
);

export const selectCategoryMap = createSelector(
  getPaymentMethodsState,
  (s) => s.categoryMap,
);

export const selectUserCountry = createSelector(
  getPaymentMethodsState,
  (s) => s.country,
);

export const selectPaymentIqMerchantId = createSelector(
  getPaymentMethodsState,
  (s) => s.paymentIqMerchantId,
);

export const selectHideAmount = createSelector(
  selectPaymentMethod,
  (s) => s.hideAmount,
);

export const selectCategoryFilterActivated = createSelector(
  getPaymentMethodsState,
  (s) => s.isCategoryFilterActivated,
);

export const selectActiveCategory = createSelector(
  getPaymentMethodsState,
  (s) => s.activeCategory,
);

export const hideTransactionButtonSelector = createSelector(
  selectPaymentMethod,
  (method) =>
    !method.description && !method.inputs.some((input) => input.required),
);

export const selectTaxConfig = createSelector(
  getPaymentMethodsState,
  (method) => method.tax,
);

export const selectRegistrationRequired = createSelector(
  getPaymentMethodsState,
  (s) => s.registrationRequired,
);

export const selectAppliedBonus = createSelector(
  getPaymentMethodsState,
  (method) => method.bonus,
);
